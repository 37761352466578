<template>
  <div>
    <template v-if="supported">
      <div class="text-center mt-6" v-if="step == 1">
        <h4>Importar {{ customerAlias }}s</h4>
        Importe seus {{ customerAlias }}s a partir da agenda do seu smartphone!
        <br />
        <v-btn class="mt-6" color="primary" @click="importContacts()"
          >Começar</v-btn
        >
      </div>
      <div v-if="step == 2">
        <!-- <v-btn @click="setResults()"> test </v-btn> -->
        <!-- <hr /> -->
        <div>
          <v-alert text type="info">
            Verifique o DDD e codigo do pais dos contatos importados!
          </v-alert>
        </div>
        <div>
          <v-btn class="mt-6" color="primary" block @click="store"
            >Importar</v-btn
          >
        </div>
        <div>
          <template v-for="(customer, index) in customers">
            <v-list-item
              class="pr-0"
              :key="index"
              @click="$emit('select', item.id)"
            >
              <v-list-item-avatar size="30">
                <CustomerAvatar :name="customer.name" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ customer.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  <small class="text--secondary"
                    >WhatsApp: <b>{{ customer.phone_wa }}</b></small
                  >
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <small class="text--secondary"
                    >Email: <b>{{ customer.email }}</b></small
                  >
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider :key="'divider' + index" />
          </template>
        </div>
      </div>
      <div class="text-center mt-6" v-if="step == '3'">
        <h4>Sucesso!</h4>
        Importação concluida
        <br />
        <v-btn class="mt-6" color="primary" @click="$router.push('/clientes')"
          >Ver {{ customerAlias }}s</v-btn
        >
      </div>
      <div class="text-center mt-6" v-if="step == 'error'">
        <h4>Ops!</h4>
        Falha ao importar
      </div>
    </template>
    <template v-else>
      A importação de contato não está disponivel no seu dispositivo.
    </template>
  </div>
</template>

<script>
import CustomerAvatar from "../components/customers/sections/CustomerAvatar.vue";
import { customerAlias } from "@/utils/customerAlias";

export default {
  components: { CustomerAvatar },
  data: () => ({
    supported: null,

    step: 1,

    selected: true,

    results: [],

    customers: [],
  }),

  created() {
    this.supported = "contacts" in navigator && "ContactsManager" in window;
  },

  computed: {
    customerAlias() {
      return customerAlias();
    },
  },

  methods: {
    async importContacts() {
      //this.step = 2; // todo
      const props = ["name", "email", "tel"];
      const opts = { multiple: true };

      try {
        const contacts = await navigator.contacts.select(props, opts);
        if (contacts.length > 0) {
          this.results = contacts;
          this.setResults();
          this.step++;
        }
      } catch (ex) {
        this.step = "error"; // todo
      }
    },

    setResults() {
      for (let contact of this.results) {
        let name = null;
        let phone_wa = null;
        let email = null;

        name = contact.name[0];

        if (contact.tel.length > 0) {
          phone_wa = contact.tel[0];
        }

        if (contact.email.length > 0) {
          email = contact.email[0];
        }

        this.customers.push({
          name: name,
          phone_wa: phone_wa,
          email: email,
        });
      }
    },

    store() {
      this.$loading.start();
      this.$http
        .store("customer/customer-import", { customers: this.customers })
        .then((response) => {
          this.$loading.finish();
          this.processSaved(response.customer); // todo
        })
        .catch((response) => {
          this.$loading.finish();
        });
    },

    processSaved() {
      this.step++;
    },
  },
};
</script>

<style>
</style>